import React, { useState, useEffect } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Table from "../../components/Table";
import SelectorGenerico from "../../components/SelectorGenerico";
import StateContext from "../../helpers/ContextState";
import { PostAsync, format, Inputs } from "../../helpers/Functions";
import Config from "../../helpers/Config";
import Select from "../../components/Select";
import Input from "../../components/InputSimple";
import SelectorMultiple from "../../components/SelectorMultiple";
import BotonVolverHome from "../../components/BotonVolverHome";
import FormControl from "react-bootstrap/FormControl";

import {
  faEye,
  faDollarSign,
  faPrint,
  faFileWord,
  faMoneyBillAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App = (props) => {
  const context = React.useContext(StateContext);
  const [param, setParam] = useState({});
  const [data, setData] = useState([]);
  const [btn, setBtn] = useState(false);
  const [inTrash, setInTrash] = useState(false);
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [total, setTotal] = useState([]);
  const [subTotal, setSubTotal] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [tipoPagos, setTipoPagos] = useState([]);
  //  const [clientes, setClientes]             =   useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [inputs2, setInputs2] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  let filter = "";
  useEffect(() => {
    basicInputs();
  }, []);

  useEffect(() => {
    if (param.ma_periodicidad_id) {
      getInit();
    } else {
      setData([]);
    }
  }, [param]);

  useEffect(() => {
    getInit();
  }, [inputs, inputs2]);

  useEffect(() => {
    getInit();
  }, [inTrash, inputs]);

  const getInit = () => {
    setData([]);
    PostAsync(
      "Pagos",
      "ListadoPagosRealizados",
      {
        filtroMultiple: JSON.stringify(inputs2),
        ...param,
        inTrash: inTrash ? 9 : 1,
        ...inputs,
      },
      context
    ).then((response) => {
      setTotal(response.totalizador);
      setSubTotal(response.subTotalDeudas);
      if (response.data && response.data.rows) {
        let pagar_ = [];
        /*voy a colocar los inputs activos por defecto para hacer los pagos*/
        response.data.rows.map((row, key) => {
          pagar_.push({ ...row, uid: row.token, key: key, pagar: false });
        });
        setData(pagar_);
        setBtn(true);
      }
    });
  };

  const basicInputs = () => {
    PostAsync(
      "Inventario",
      "ListadoCompras",
      {
        ...param,
        inTrash: inTrash ? 9 : 1,
        fecha: "0000-00-00",
        limit: 0,
        start: 0,
      },
      context
    ).then((response) => {
      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas);
      }

      if (response.data && response.data.proveedores) {
        setProveedores(response.data.proveedores);
      }

      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas);
      }

      if (response.data && response.data.productos) {
        setProductos(response.data.productos);
      }

      if (response.data && response.data.tipo_pagos) {
        setTipoPagos(response.data.tipo_pagos);
      }
    });
  };

  const selection = (row, key) => {
    let data_ = [...data];
    data_[key].pagar = data_[key].pagar === true ? false : true;
    setData(data_);
    if (checkActive()) {
      setBtn(true);
    } else {
      setBtn(false);
    }

    let checkedItems = data_.filter((elem) => elem.pagar);
    setSelectedItems(checkedItems);
    
    data.length === checkedItems.length
      ? setSelectAll(true)
      : setSelectAll(false);
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      let tokens = selectedItems.map(item => item.token).join('-');
      setSelectedItemsIds(tokens);
    }
  }, [selectedItems]);
  
  console.log("Selected",selectedItemsIds);
  
  
  const handleCheck = () => {
    let data_ = [];
    let seleccion = selectAll;
    // console.log("seleccion",seleccion);

    data.map((row, key) => {
      row.pagar = seleccion ? false : true;
      data_.push(row);
    });
    setData(data_);
    setSelectAll(seleccion ? false : true);
    if (!seleccion) {
      setSelectedItems(data_);
    }else{
      setSelectedItems([]);
    }
  };
  
  // console.log("SelectAll", selectAll)
  const handleSubmitPagar = () => {
    if (checkActive()) {
      PostAsync(
        "Pagos",
        "SetPagos",
        { data: JSON.stringify(data) },
        context
      ).then((response) => {
        getInit();
      });
    }
  };

  const checkActive = () => {
    const boolean = data.find((search) => search.pagar === true);
    return boolean !== undefined ? true : false;
  };

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs);
  };
  const objetoSerializado = encodeURIComponent(JSON.stringify(inputs2));
  return (
    <div className="container">
      <BotonVolverHome />

      <div className="row justify-content-center mb-3">
        <div className="col-12 mb-2">
          <SelectorGenerico
            modelo="Maestros"
            metodo="get_tablas_maestras"
            tabla="ma_periodicidad"
            param={param}
            lg="3"
            name="ma_periodicidad_id"
            setParam={setParam}
          />
        </div>
        <div className="col-3 mb-2">
          <div className="d-grid gap-2">
            <div
              className={
                !inTrash
                  ? "btn btn-warning btn-sm btn-block"
                  : "btn btn-dark btn-sm btn-block"
              }
              onClick={() => {
                setInTrash(inTrash ? false : true);
                // console.log(inTrash)
              }}
            >
              Pagos {!inTrash ? "Eliminados" : "No eliminados"}
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-gray">
        <div className="col-12 ">
          <b>Filtros de búsqueda en pagos efectuados</b>
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            url="/Usuarios/Lista"
            name="proveedor_id"
            title="Proveedor"
            selectDefault="Proveedor"
            data={proveedores}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            url="/Usuarios/Lista"
            name="es_tipopago"
            title="Tipo de pago"
            selectDefault="Tipo de pago"
            data={tipoPagos}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <Input
            defaultValue={filter}
            className="form-control"
            name="filter"
            onChange={onChangeCustom}
            placeholder="Buscar por ID o NIT"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th className="col">N. Comprobante</th>
                <th className="col">Fecha de pago</th>
                <th className="col">ID o NIT</th>
                <th className="col">Correo contacto</th>
                <th className="col">Teléfono contacto</th>
                <th className="col">Dirección</th>
                <th className="col">Proveedor</th>
                <th className="col">SubTotal</th>
                <th className="col">T. Pagado</th>
                <th className="text-center col">
                  Acciones
                  <div>
                    {!inTrash && data.length > 0 ? (
                      <>
                        <a
                          target="_blank"
                          href={
                            Config.ConfigApirest +
                            "PDF/Pagos/ListadoPagosRealizadosDetalle/any?pdfBool=true&fecha_inicio=" +
                            inputs.fecha_inicio +
                            "&fecha_fin=" +
                            inputs.fecha_fin +
                            "&semana=" +
                            inputs.semana +
                            "&ma_periodicidad_id=" +
                            param.ma_periodicidad_id +
                            "&inTrash=" +
                            inTrash +
                            "&filter=" +
                            inputs.filter +
                            "&filtroMultiple=" +
                            objetoSerializado
                          }
                          className="ms-2"
                        >
                          <FontAwesomeIcon icon={faPrint} />
                        </a>
                        <a
                          className="ms-2"
                          target="_blank"
                          href={
                            Config.ConfigApirest +
                            "Excel/Pagos/WordOfficePagosGlobal/filtroMultiple:" +
                            objetoSerializado +
                            "/fecha_inicio:" +
                            inputs.fecha_inicio +
                            "/fecha_fin:" +
                            inputs.fecha_fin +
                            "/ma_periodicidad_id:" +
                            param.ma_periodicidad_id +
                            "/semana:" +
                            inputs.semana +
                            "/seleccion:" +
                            selectedItemsIds
                          }
                        >
                          <FontAwesomeIcon icon={faFileWord} />
                        </a>
                        <a
                          className="ms-2"
                          target="_blank"
                          href={
                            Config.ConfigApirest +
                            "Excel/Pagos/ExportarExcelPagosBancolombiaData/filtroMultiple:" +
                            objetoSerializado +
                            "/fecha_inicio:" +
                            inputs.fecha_inicio +
                            "/fecha_fin:" +
                            inputs.fecha_fin +
                            "/ma_periodicidad_id:" +
                            param.ma_periodicidad_id +
                            "/semana:" +
                            inputs.semana +
                            "/seleccion:" +
                            selectedItemsIds
                          }
                        >
                          {/* <FontAwesomeIcon icon={faFileWord} /> */}
                          <FontAwesomeIcon icon={faMoneyBillAlt} />
                        </a>
                      </>
                    ) : (
                      false
                    )}
                  </div>
                </th>
                <th className="text-center col">
                  <div
                    className={
                      selectAll
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-secondary btn-sm me-2"
                    }
                    onClick={handleCheck}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Sin resultados...</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                data.map((row, key) => {
                  // console.log(row)
                  return (
                    <tr key={key}>
                      <td>{row.nComprobante}</td>
                      <td>{row.fecha_pago_efectiva_string}</td>
                      <td>{row.id_or_nit}</td>
                      <td>{row.email}</td>
                      <td>{row.celular}</td>
                      <td>{row.direccion}</td>
                      <td>
                        {row.proveedor}
                      </td>
                      {/* <td>{format(parseFloat(( row.peso > 0 )?row.precio * row.peso:row.precio * row.cantidad).toFixed(2))}</td> */}
                      <td>{format(row.subtotal)}</td>
                      <td>{format(row.deuda_provedor)}</td>
                      <td className="text-center border-start">
                        {!inTrash ? (
                          <>
                            <NavLink
                              to={
                                "/Pagos/ListaEfectados/Detalle/" +
                                row.token_proveedor +
                                "/" +
                                row.nComprobante +
                                "?pdfBool=true&fecha_inicio=" +
                                row.fecha_inicio +
                                "&fecha_fin=" +
                                row.fecha_fin +
                                "&semana=" +
                                inputs.semana
                              }
                              className="me-2"
                              title="Ver Detalle"
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </NavLink>
                          </>
                        ) : (
                          <NavLink
                            to={
                              "/Pagos/ListaEfectados/DetalleEliminado/" +
                              row.token
                            }
                            className="btn btn-warning btn-sm"
                          >
                            Detalle
                          </NavLink>
                        )}
                      </td>
                      <td className="text-center ">
                        {parseInt(context.user.tipo_usuario_id) <= 6 ? (
                          <div
                            className={
                              row.pagar === true
                                ? "btn btn-primary btn-sm me-2"
                                : "btn btn-secondary btn-sm me-2"
                            }
                            onClick={() => selection(row, key)}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : (
                          false
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
            <tfoot>
              <tr className="bg-primary">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-end text-white">{format(subTotal)}</td>
                <td className="text-end text-white">{format(total)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
export default App;
