const exportar  = {
  Prefijo:"/pwa",
  ConfigAppUrl : window.location.origin+"/",
  ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.pagoslocales.com:3020/':(process.env.NODE_ENV==='development')?'https://socket.pagoslocales.com:3020/':'https://socket.pagoslocales.com:3020/',
  ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
  //ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://apirest.fenixteayuda.com/':'https://apirest.fenixteayuda.com/',
  //ConfigApirest   : (document.location.hostname==='localhost' && 1===1)?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://asocolcarnesbackend.programandoweb.net/':'https://asocolcarnesbackend.programandoweb.net/',
  ConfigApirest   : (document.location.hostname==='localhost' && 1==3)?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://apirest.fenixteayuda.com/':'https://apirest.fenixteayuda.com/',
  Title:'Inventory',
  Alt:'Inventory',
}
export default exportar

// const exportar  = {
//   Prefijo:"/pwa",
//   ConfigAppUrl : window.location.origin+"/",
//   ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.pagoslocales.com:3020/':(process.env.NODE_ENV==='development')?'https://socket.pagoslocales.com:3020/':'https://socket.pagoslocales.com:3020/',
//   ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
//   //ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://apirest.fenixteayuda.com/':'https://apirest.fenixteayuda.com/',
//   //ConfigApirest   : (document.location.hostname==='localhost' && 1===1)?'http://localhost/apirest_inventory/':(process.env.NODE_ENV==='development')?'https://asocolcarnesbackend.programandoweb.net/':'https://asocolcarnesbackend.programandoweb.net/',
//   // ConfigApirest   :  'http://localhost/asocolcarnes-backend-api/',
//   // ConfigApirest   :  'http://localhost:8082/asocolcarnes-backend-api/',
//   ConfigApirest   :  'https://apirest.fenixteayuda.com/',
//   Title:'Inventory',
//   Alt:'Inventory',
// }
// export default exportar

// Esta es para el local!!!!